import React from 'react'
import * as S from './styled'
import { Fragment } from 'react'
import errorImage from '../../assets/images/erro-404.png'
import { ArrowLeft } from '@dasa-health/alma-icons'

const NotFound = () => {
  return (
    <Fragment>
      <S.ContainerNotFound>
        <S.ContentNotFound>
          <S.TitleNotFound>
            Poxa, a página <br /> não existe.
          </S.TitleNotFound>
          <S.TextNotFound>
            Tem certeza que digitou certinho? Que tal conferir e tentar de novo?
          </S.TextNotFound>
          <S.LinkNotFound href="/">
            <ArrowLeft /> Voltar para a página inicial.
          </S.LinkNotFound>
        </S.ContentNotFound>
        <S.ContentImgNotFound>
          <S.ImgNotFound src={errorImage} alt="Dasa - Erro 404" />
        </S.ContentImgNotFound>
      </S.ContainerNotFound>
      <S.Line />
    </Fragment>
  )
}

export default NotFound
