import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { sendNotificationNotFound } from '../services/notificationService'
import NotFound from '../components/not-found'

const NotFoundPage = ({ location, pageContext }) => {
  useEffect(() => {
    const sendNotificationErr = async () => {
      await sendNotificationNotFound(location)
    }

    sendNotificationErr()
  }, [location])

  return (
    <Layout location={location} pageContext={pageContext}>
      <SEO title="404: Not found" />
      <NotFound />
    </Layout>
  )
}

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default NotFoundPage
