import styled from 'styled-components'
import media from 'styled-media-query'
import { PinkLine } from '../shared/PinkLine/styled'
import { Link } from 'gatsby'

export const ContainerNotFound = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;
  gap: 10rem;
  flex-wrap: wrap;
  ${media.lessThan('medium')`
    gap: 0;
    padding-bottom: 0;
  `}
`
export const ContentNotFound = styled.div``

export const Line = styled(PinkLine)`
  ${media.lessThan('medium')`
    width: 100vw;
  `}
`
export const TitleNotFound = styled.h1`
  font-size: 60px;
  color: ${props => props.theme.colors.brand.primary[10]};
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -1px;
  margin: 0 0 3.2rem;
  width: 100%;

  ${media.lessThan('medium')`
    font-size: 40px;
    margin: 0 0 2.4rem 0;
    text-align: center;
  `}
`
export const TextNotFound = styled.p`
  font-size: 13px;
  color: var(--blue);
  font-weight: 400;
  margin: 0 0 3.2rem;
  max-width: 75.9rem;

  ${media.lessThan('medium')`
    text-align: center;
  `}
`
export const LinkNotFound = styled(Link)`
  color: var(--pink);
  text-decoration: none;
  background-color: transparent;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.5;
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 1rem;

  ${media.lessThan('medium')`
    justify-content: center;
  `}
`
export const ImgNotFound = styled.img`
  width: 100%;
`
export const ContentImgNotFound = styled.div`
  height: 250px;
  width: 250px;
`
